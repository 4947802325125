@text-gray: #AAAAAA;
@white: #FFFFFF;
@black: #000000;
@off-black: #1D1D1D;
@dark-gray: #222222;
@light-gray: #EBEBEB;
@line-gray: #606060;
@market-main: #BB261A;
@market-hover: #215583;

.juicer-feed a:hover {
  color: #b2b2b2;
}
.juicer-feed .j-meta a:hover {
  color: #000000;
}
.j-twitter-poster:hover {
  color: #b2b2b2;
}

.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --market-main: @market-main;
  --market-hover: #BB261A;
  --mobile-nav-level2-bg: #CDCDCD;
  --mobile-nav-level2-fg: white;
  --mobile-nav-level3-bg: white;
  --mobile-nav-level3-fg: black;
}

@import '_base.less';

html body {
  padding-bottom: 70px;
  @media(min-width: @screen-sm-min) {
    padding-bottom: 50px;
  }
}

.fa.fa-twitter::before {
  content: "𝕏"!important;
}

body.site--honda {
  padding-top: 8  0px;
  @media(min-width: @screen-sm-min) {
    padding-top: 125px;
  }
  @media(min-width: @screen-md-min) {
    padding-top: 155px;
  }
}

.homepage-content-mixin(@sm-to-md: 2, @lg: 2) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0;
  margin-top: 30px;
  flex-direction: row;
  .content-item {
    padding: 0!important;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
    // sm <-> md
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
      border-left: solid 5px #EBEBEB;
      border-right: solid 5px #EBEBEB;
      width: calc(100% / @sm-to-md);
      margin-bottom: 0;
    }

    @media (min-width: @screen-lg-min) {
      border-left: solid 10px #EBEBEB;
      border-right: solid 10px #EBEBEB;
      margin-bottom: 0;
      width: calc(100% / @lg);
    }

    figure {
      width: 100%;
    }

    h5.content-title {
      margin: 5px 0;
      @media (min-width: @screen-sm-min) {
        margin: 10px 0 5px;
      }
      &:hover {
        color: @market-main;
        cursor: pointer;
      }
      a {
        &:hover {
          color: @market-main;
          cursor: pointer;
        }
      }
      .content-title-text {
        color: @black;
        font-size: 14px;
        line-height: 1.4;
        @media (min-width: @screen-sm-min) {
          font-size: 16px;
        }
      }
    }
    h3.content-title {
      margin: 5px 0;
      @media (min-width: @screen-sm-min) {
        margin: 10px 0 5px;
      }
      .content-title-text {
        color: @black;
        font-size: 16px;
        font-weight: bold;
        @media (min-width: @screen-sm-min) {
          font-size: 18px;
        }
        @media (min-width: @screen-md-min) {
          font-size: 22px;
        }
      }
    }
    h5.content-summary {
      margin: 0;
      .content-summary-text {
        color: @black;
        font-size: 14px;
        line-height: 1.4;
        @media (min-width: @screen-sm-min) {
          font-size: 16px;
        }
      }
    }
  }
  .content-item.why-video-item {
    width: 100%;
  }

}


/* ==== NAVBAR ========================================================== */
/* ====================================================================== */
.navbar.site-navbar {
  @media(max-width: @screen-xs-max) {
    display: flex;
    align-items: center;
  }
  height: 40px;
  min-height: 40px;
  @media(min-width: @screen-sm-min) {
    height: 85px;
  }
  @media(min-width: @screen-md-min) {
    height: 115px;
  }
  .navbar-container {
    height: 40px;
    @media(min-width: @screen-sm-min) {
      height: 85px;
    }
    @media(min-width: @screen-md-min) {
      height: 115px;
    }
    .nav-header {
      display: block;
      h1 {
        color: @market-main;
        font-size: 18px;
        font-weight: bold;
        margin: 5px 0;
        @media(min-width: @screen-sm-min) {
          font-size: 26px;
          font-weight: 900;
        }
        @media(min-width: @screen-md-min) {
          font-size: 32px;
        }
      }
      h2 {
        color: @line-gray;
        font-size: 16px;
        margin: 0;
        @media(min-width: @screen-md-min) {
          font-size: 18px;
        }
      }
    }
  }
}
.homepage-market-navbar-container {
  top: 40px;
  @media(min-width: @screen-sm-min) {
    top: 85px;
  }
  @media(min-width: @screen-md-min) {
    top: 115px;
  }
}
.navbar.site-navbar.sticky-navbar.hide-nav {
  transform: translateY(-115px);
}
.homepage.homepage-market-navbar-container.hide-market-nav {
  transform: translateY(-155px);
}


/* ==== SOCIAL MEDIA SIDEBAR ============== ============================== */
/* ====================================================================== */
.social-media-sidebar {
  box-shadow: -5px 5px 10px -10px rgba(0,0,0,0.75);
  right: 0;
  top: 85px;
  position: fixed;
  z-index: 9;
  @media(min-width: @screen-sm-min) {
    top: 140px;
  }
  @media(min-width: @screen-md-min) {
    top: 175px;
  }
  .social-media {
    display: flex;
    flex-direction: column;
  }
  a {
    background: @light-gray;
    border: none;
    font-size: 14px;
    margin-bottom: 1px;
    padding: 6px 8px;
    @media(min-width: @screen-sm-min) {
      font-size: 16px;
    }
    @media(min-width: @screen-md-min) {
      font-size: 20px;
      padding: 8px 10px;
    }
  }
  .facebook {
    color: #4267B2;
    &:hover {
      background: #4267B2;
      color: @white;
    }
  }
  .twitter {
    color: #000000;
    &:hover {
      background: #000000;
      color: @white;
    }
  }
  .linkedin {
    color: #0072b1;
    &:hover {
      background: #0072b1;
      color: @white;
    }
  }
}

/* ==== CAROUSEL ======================================================== */
/* ====================================================================== */
.homepage-carousel-container {
  #homepage-carousel {
    .item {
      .homepage-carousel-caption {
        background-color: @light-gray;
        @media (max-width: @screen-sm-max) {
          background-color: @market-main;
        }
        h2 {
          font-size: 18px;
          @media (min-width: @screen-sm-min) {
            font-size: 28px;
          }
          @media (min-width: @screen-md-min) {
            font-size: 30px;
          }
        }
        p {
          color: @white;
          display: block;
          font-size: 16px;
          line-height: 1.3;
          @media (min-width: @screen-sm-min) {
            font-size: 18px;
          }
          @media (min-width: @screen-md-min) {
            color: @black;
          }
        }
      }
    }
  }
}

/* ==== CONTENT SECTIONS ================================================ */
/* ====================================================================== */
.homepage.container {
  margin-bottom: 10px;
  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px;
  }
  @media(min-width: @screen-md-min) {
    margin-bottom: 30px;
  }
}
.homepage {
  .homepage-section {
    .homepage-section-container {
      padding: 10px 10px;
      @media(min-width: @screen-sm-min) {
        padding: 20px 10px;
      }
      @media(min-width: @screen-md-min) {
        padding: 30px 20px;
      }
      .homepage-section-header {
        h2 {
          font-size: 18px;
          margin-bottom: 10px;
          @media(min-width: @screen-sm-min) {
            font-size: 24px;
            margin-bottom: 0;
          }
          @media(min-width: @screen-md-min) {
            font-size: 30px;
          }

          &.hmd-section-title {
            text-transform: none;
          }
        }
        h3 {
          color: @line-gray;
          margin: 5px 0 0 0;
          font-size: 14px;
          line-height: 1.4;
          @media(min-width: @screen-sm-min) {
            //margin-bottom: 20px;
            margin-bottom: 0;
          }
          @media(min-width: @screen-md-min) {
            font-size: 18px;
            //margin-bottom: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
    .content-container {
      .homepage-content-mixin(2, 2);
      .form-control {
        border: none;
      }
    }
    .content-container.three-items {
      .homepage-content-mixin(3, 3);
    }
  }
}
.content-information-actions {
  display: flex;
  justify-content: space-around;
  margin: 10px 0 0;
  width: 100%;
  @media(min-width: @screen-sm-min) {
    margin: 20px 0 0;
  }
  @media(min-width: @screen-md-min) {
    margin: 30px 0 0;
  }
}
.homepage-button {
  border: none;
  border-radius: 0;
  background-color: @market-main;
  color: @white;
  font-size: 14px;
  padding: 5px 10px;
  text-transform: uppercase;
  &:hover {
    background-color: @white;
    color: @market-main;
  }
  &:disabled {
    color: #e5e5e5;
    background-color: #989898;
  }
  @media(min-width: @screen-sm-min) {
    font-size: 16px;
  }
  @media(min-width: @screen-md-min) {
    font-size: 18px;
    font-weight: bold;
  }
}
.content-button {
  color: @market-main;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

/* ==== FOOTER ========================================================== */
/* ====================================================================== */
.site-footer-container--legal {
  height: 70px;
  @media(min-width: @screen-sm-min) {
    height: 50px;
  }
  .site-footer-legal {
    height: 70px;
    padding: 15px;
    @media(min-width: @screen-sm-min) {
      height: 50px;
    }
  }
}
.copyright-col {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media(max-width: @screen-xs-max) {
    align-items: center;
    flex-direction: column;
  }
  .back-to-top {
    color: @white!important;
    padding: 0;
    i {
      color: @white!important;
      padding-left: 5px;
    }
  }
}

/* ==== PAGES =========================================================== */
/* ====================================================================== */
.page {
  margin-bottom: 10px;
  @media (min-width: @screen-sm-min) {
    margin-bottom: 20px;
  }
  @media (min-width: @screen-md-min) {
    margin-bottom: 30px;
  }
  header {
    margin-bottom: 10px;
    @media (min-width: @screen-sm-min) {
      margin-bottom: 20px;
    }
    @media (min-width: @screen-md-min) {
      margin-bottom: 30px;
    }

    h1 {
      margin: 0;
      @media (min-width: @screen-sm-min) {
        margin-top: 15px
      }
      @media (min-width: @screen-md-min) {
        margin-top: 30px
      }
    }
  }
  //.homepage-button {
  //  font-size: 14px;
  //}
}
.mobile-page-col {
  @media(max-width: @screen-xs-max) {
    padding: 0;
  }
}
.why-honda-caption {
  background-color: @market-main;
  padding: 10px;
  @media(min-width: @screen-sm-min) {
    padding: 20px;
  }
  @media(min-width: @screen-md-min) {
    padding: 30px;
  }
  p {
    color: @white;
    font-size: 16px;
    line-height: 1.3;
    margin: 0;
  }
}
.page-section {
  background-color: @light-gray;
  padding: 10px;
  margin: 10px 0 0;
  @media(min-width: @screen-sm-min) {
    padding: 20px;
    margin: 20px 0 0;
  }
  @media(min-width: @screen-md-min) {
    padding: 30px;
    margin: 30px 0 0;
  }
}
.page-section-header {
  margin-bottom: 10px;
  text-align: center;
  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px;
  }
  @media(min-width: @screen-md-min) {
    margin-bottom: 30px;
  }
  h2 {
    color: @market-main;
    font-weight: bold;
    margin: 0;
    text-transform: initial;
  }
  p {
    color: @line-gray;
    font-size: 16px;
    margin: 10px 0 0;
  }
}
.page-section-content-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.page-section-content-item {
  display: flex;
  width: 100%;
  border-left: none;
  border-right: none;
  margin-top: 10px;
  padding: 10px;
  @media(min-width: @screen-sm-min) {
    display: block;
    width: 33.33333333%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    padding: 0;
  }
  @media(min-width: @screen-md-min) {
    width: 20%;
    margin-top: 0;
  }
}
.page-section-content-item-info {
  @media(max-width: @screen-xs-max) {
    margin-left: 10px;
  }
  @media(min-width: @screen-sm-min) {
    padding-top: 5px;
  }
  h5 {
    font-weight: bold;
    font-size: 14px;
    margin: 5px 0;
    text-transform: initial;
  }
  p {
    font-size: 14px;
    line-height: 1.3;
    margin: 0;
  }
}
.page-section-content-large {
  margin-top: 10px;
  @media(min-width: @screen-sm-min) {
    margin-top: 20px;
  }
  @media(min-width: @screen-md-min) {
    margin-top: 30px;
  }
}
.page-section-content-item-large {
  img {
    width: 100%;
  }
}
.page-section-content-item-info-large {
  margin: 0;
  h5 {
    font-size: 16px;
    @media(min-width: @screen-sm-min) {
      font-size: 18px;
    }
  }
}
.page-section-content-item-moderator {
  display: flex;
  margin-bottom: 10px;
  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px;
  }
  @media(min-width: @screen-md-min) {
    margin-bottom: 30px;
  }
  @media(min-width: @screen-lg-min) {
    margin: 0 70px 30px;
  }
  img {
    min-width: 160px;
    @media(min-width: @screen-sm-min) {
      min-width: 190px;
    }
    @media(min-width: @screen-md-min) {
      min-width: 160px;
    }
    @media(min-width: @screen-lg-min) {
      min-width: 190px;
    }
  }
  .page-section-content-item-info {
    padding: 0;
    @media(min-width: @screen-sm-min) {
      padding: 0 30px;
    }
    h5 {
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 5px;
      @media(min-width: @screen-sm-min) {
        font-size: 18px;
        margin: 20px 0 5px;
      }
    }
    p {
      font-size: 14px;
      @media(min-width: @screen-sm-min) {
        font-size: 16px;
      }
    }
  }
}
.page-well {
  background-color: @market-main;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
  }
  @media(min-width: @screen-md-min) {
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 30px;
  }
  h2 {
    color: @white;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    text-transform: initial;
    @media(min-width: @screen-sm-min) {
      font-size: 22px;
    }
    @media(min-width: @screen-md-min) {
      font-size: 28px;
    }
  }
}

.contact-us-grid {
  h2 {
    color: @market-main;
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px;
    text-transform: initial;
    text-align: center;
    @media(min-width: @screen-sm-min) {
      font-size: 22px;
      margin: 0 0 20px;
    }
    @media(min-width: @screen-md-min) {
      font-size: 26px;
      margin: 0 0 30px;
    }
  }
}
.contact-us-grid-container {
  display: flex;
  flex-wrap: wrap;
}
.contact-us-grid-item {
  display: flex;
  margin: 0 0 10px;
  padding: 0 10px;
  @media(min-width: @screen-sm-min) {
    display: block;
    margin: 0 0 20px;
    width: 33.33333333%;
    padding: 0 10px;
  }
  @media(min-width: @screen-md-min) {
    margin: 0 0 30px;
    padding: 0 15px;
  }
  img {
    margin-bottom: 5px;
  }
}
.contact-us-grid-item-img {
  @media (max-width: @screen-xs-max) {
    width: 30%;
    margin-right: 10px;
  }
}
.contact-us-grid-item-info {
  @media (max-width: @screen-xs-max) {
    width: 70%;
  }
  @media (min-width: @screen-sm-min) {
    padding-top: 5px;
  }
}
.contact-us-grid-title {
  color: @black;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
  @media(min-width: @screen-sm-min) {
    color: @market-main;
    font-size: 16px;
  }
}

.hmd-contact-us-page-form-row {
  @media(min-width: @screen-sm-min) {
    margin-top: 10px;
  }
  @media(min-width: @screen-md-min) {
    margin-top: 30px;
  }
}